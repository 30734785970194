<template>
  <div
    class="modal fade"
    id="modalAccountEstimated"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Pemasukan</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Akun Perkiraan </label>
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Masukkan Akun Perkiraan"
                v-model="formModal.nama"
              />
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Nilai </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  class="form-control text-right"
                  id="inlineFormInputGroupUsername"
                  placeholder="Masukan Nilai"
                  :model-modifiers="{ number: true }"
                  v-money="configMoney"
                  v-model.lazy="formModal.nilai"
                />
              </div>
            </div>
            <!-- <div class="form-group d-flex align-items-center">
                            <label class="form-label">Departemen </label>
                            <Select2 v-model="Departemen" :options="OptionDepartemen" style="width:100% !important"
                                placeholder="Pilih Departemen" :settings="{}" @change="myChangeEvent($event)"
                                @select="mySelectEvent($event)" />
                        </div> -->
          </div>
          <div class="modal-footer">
            <button type="button" @click="saveDetail" class="btn btn-save">
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ label }} Pengeluaran</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-spending">Pengeluaran </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ label }}
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <div v-if="isEditable && formData.status == 'aktif'">
                <button class="btn btn-save" :disabled="isSubmit">
                  <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                  Simpan
                </button>
              </div>
              <div v-show="formData.status == 'waiting' && checkAccess('kas_bank', 'agree_credit')">
                <button
                  class="btn btn-save mr-2"
                  style="background: #ffa736; border-color: #ffa736"
                  type="button"
                  @click="accChange"
                  :disabled="isSubmit"
                >
                  <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                  Setujui
                </button>
                <button
                  class="btn btn-save mr-2"
                  style="background: #ff5252; border-color: #ff5252"
                  type="button"
                  @click="rejectChange"
                  :disabled="isSubmit"
                >
                  <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                  Tolak
                </button>
              </div>
            </div>
          </div>

          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">Detail Pengeluaran</div>
                <div class="sub-title">
                  {{ label }} informasi yang diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="mr-auto mb-3" v-if="formData.status == 'waiting'">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="pills-newdata-tab"
                    data-toggle="pill"
                    href="#pills-newdata"
                    role="tab"
                    aria-controls="pills-newdata"
                    aria-selected="true"
                    >New Data</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pills-old-data-tab"
                    data-toggle="pill"
                    href="#pills-old-data"
                    role="tab"
                    aria-controls="pills-old-data"
                    aria-selected="false"
                    >Old Data</a
                  >
                </li>
              </ul>
            </div>

            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-newdata"
                role="tabpanel"
                aria-labelledby="pills-newdata-tab"
              >
                <div class="row">
                  <div class="col-12" v-if="msgAlert">
                    <div class="alert alert-info" :style="{ fontSize: '15px' }">
                      <h4 class="alert-heading" :style="{ fontSize: '17px' }">
                        Perhatian!
                      </h4>
                      <p class="mb-0" v-if="msgAlert == 'reimburse'">
                        Data pengeluaran ini berkaitan dengan reimburse <RouterLink :to="{
                          name: 'DetailReimburse',
                          params: {
                            id: id_reimburse,
                          },
                        }" style="cursor:pointer">{{ id_reimburse }}</RouterLink>, untuk mengubah data pengeluaran anda bisa melakukannya di detail remburse
                      </p>
                      <p class="mb-0" v-else-if="msgAlert == 'project'">
                        Data pengeluaran ini berkaitan dengan project <RouterLink :to="{
                          name: 'DetailProject',
                          params: {
                            kode: kodeProject,
                          },
                        }" style="cursor:pointer">{{ kodeProject }}</RouterLink>, untuk mengubah data pengeluaran anda bisa melakukannya di detail project
                      </p>
                      <p class="mb-0" v-else-if="msgAlert == 'payroll'">
                        Data pengeluaran ini berkaitan dengan payroll{{ id_payroll }}
                      </p>
                      <p class="mb-0" v-else-if="msgAlert == 'aset'">
                        Data pengeluaran ini berkaitan dengan pembelian aset <RouterLink :to="{
                          name: 'DetailAsset',
                          params: {
                            id: kode_aset,
                          },
                        }" style="cursor:pointer">{{ kode_aset }}</RouterLink>
                      </p>
                      <p class="mb-0" v-else-if="msgAlert == 'hutang'">
                        Data pengeluaran ini berkaitan dengan pembayaran hutang <RouterLink :to="{
                          name: 'DetailAccountPayable',
                          params: {
                            id: id_hutang,
                          },
                        }" style="cursor:pointer">{{ id_hutang }}</RouterLink>
                      </p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div v-if="label === 'Edit'" class="form-group">
                      <label>Nomor : </label>
                      <input
                        type="text"
                        class="form-control"
                        :disabled="!isEditable"
                        v-model="formData.no_transaksi"
                        id="inputAddress2"
                        readonly
                        placeholder="Masukkan Nomor"
                      />
                    </div>
                    <div class="form-group">
                      <label>Tanggal :</label>
                      <Datepicker
                        :autoApply="true"
                        :disabled="!isEditable"
                        v-model="date"
                        :format="format"
                        :maxDate="new Date()"
                        :class="{
                          'is-invalid': formError && formError.tanggal,
                        }"
                        :enableTimePicker="false"
                        locale="id-ID"
                        selectText="Pilih"
                        cancelText="Batal"
                        @update:modelValue="changeDate"
                      ></Datepicker>
                      <div
                        class="form-error"
                        v-if="formError && formError.tanggal"
                      >
                        {{ formError.tanggal }}
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group">
                        <label class="form-label">Departemen </label>
                        <Select2
                          v-model="formData.id_divisi"
                          :disabled="!isEditable"
                          :options="OptionDepartemen"
                          style="width: 100% !important"
                          placeholder="Pilih Departemen"
                          :settings="{
                            allowClear: !this.accessDepartement ? true : false,
                          }"
                          @change="formChange('id_divisi')"
                          @select="formChange('id_divisi')"
                        />
                      </div>
                      <label>Akun :</label>
                      <Select2
                        v-model="formData.id_akun"
                        :disabled="!isEditable"
                        :class="{
                          'is-invalid': formError && formError.id_akun,
                        }"
                        :options="OptionKasBank"
                        placeholder="Pilih Akun"
                        :settings="{ templateResult: formatState }"
                        @change="formChange($event)"
                        @select="formChange($event)"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_akun"
                      >
                        {{ formError.id_akun }}
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label for="no_referensi">Nomor Referensi :</label>
                      <input
                        type="text"
                        :disabled="!isEditable"
                        class="form-control"
                        v-model="formData.no_referensi"
                        id="no_referensi"
                        @keyup="formChange('no_referensi')"
                        placeholder="Masukkan Nomor Referensi"
                      />
                    </div>

                    <div class="form-group">
                      <label>Status :</label>
                      <Select2
                        v-model="formData.status"
                        :disabled="!isEditable"
                        :class="{ 'is-invalid': formError && formError.status }"
                        :options="statusCompany"
                        placeholder="Status"
                        :settings="{
                          settingOption: value,
                          settingOption: value,
                        }"
                        @change="formChange('status')"
                        @select="formChange('status')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.status"
                      >
                        {{ formError.status }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="keterangan">Catatan :</label>
                      <textarea
                        type="text"
                        :disabled="!isEditable"
                        v-model="formData.keterangan"
                        class="form-control"
                        id="keterangan"
                        placeholder="Masukkan Keterangan"
                        @keyup="formChange('catatan')"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="title">Rincian Pengeluaran</div>
                    <div class="sub-title">
                      Rincian yang ada pada Pengeluaran
                    </div>
                  </div>
                </div>
                <hr />
                <div class="d-flex justify-content-between">
                  <div class="search">
                    <div
                      class="form-group"
                      style="width: 400px"
                      v-if="isEditable"
                    >
                      <Select2
                        v-model.lazy="AccountEstimated"
                        :options="OptionAccountEstimated"
                        placeholder="Pilih akun perkiraan..."
                        :disabled="accessDepartement !== '' && accessDepartement && !formData.id_divisi"
                        :settings="{
                          templateResult: formatState,
                        }"
                        @change="formChange('')"
                        @select="mySelectAccountEstimated($event)"
                      />
                    </div>
                  </div>
                  <div class="card-total">
                    <div class="total-debit d-flex flex-column">
                      <span>Nilai</span>
                      <span class="ml-auto"
                        >Rp {{ formatMoney(formData.nilai) }}</span
                      >
                    </div>
                  </div>
                </div>

                <div v-if="formError && formError.nilai">
                  <div
                    class="form-error"
                    :style="{ marginBottom: '5px', fontSize: '15px' }"
                  >
                    Masukan Terlebih dahulu rincian pengeluaran
                  </div>
                </div>

                <div class="table-responsive mt-4">
                  <table
                    class="table table-bordered table-striped"
                    id="tableDepartment"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Akun</th>
                        <th style="width: 20%">Nama Akun</th>
                        <th style="width: 20%">Nilai</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, index) in formDetail"
                        :key="index"
                        @click="
                          editDetail(
                            index,
                            value.akun,
                            value.nama,
                            value.nilai,
                            $event
                          )
                        "
                        :style="{ cursor: isEditable ? 'pointer' : 'default' }"
                      >
                        <td>{{ value.akun }}</td>
                        <td>{{ value.nama }}</td>
                        <td
                          class="text-right position-relative"
                          style="padding-right: 40px"
                        >
                          {{
                            value.tipe == "credit"
                              ? "-" + formatMoney(value.nilai)
                              : formatMoney(value.nilai)
                          }}
                          <img
                            src="/img/avatar/icon-delete.svg"
                            v-if="isEditable"
                            alt=""
                            class="btn-add-row"
                            style="
                              position: absolute;
                              right: 10px;
                              width: 20px;
                              top: 50%;
                              transform: translateY(-50%);
                              cursor: pointer;
                            "
                          />
                        </td>
                      </tr>
                      <tr v-if="formDetail.length === 0">
                        <td colspan="3" class="text-center">
                          No data available in table
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-old-data"
                role="tabpanel"
                aria-labelledby="pills-old-data-tab"
              >
                <div class="row">
                  <div class="col-6">
                    <div v-if="label === 'Edit'" class="form-group">
                      <label>Nomor : </label>
                      <input
                        type="text"
                        class="form-control"
                        :disabled="!isEditable"
                        v-model="oldformData.no_transaksi"
                        id="inputAddress2"
                        readonly
                        placeholder="Masukkan Nomor"
                      />
                    </div>
                    <div class="form-group">
                      <label>Tanggal :</label>
                      <Datepicker
                        :autoApply="true"
                        :disabled="!isEditable"
                        v-model="oldformData.tanggal"
                        :format="format"
                        :maxDate="new Date()"
                        :class="{
                          'is-invalid': formError && formError.tanggal,
                        }"
                        :enableTimePicker="false"
                        locale="id-ID"
                        selectText="Pilih"
                        cancelText="Batal"
                        @update:modelValue="changeDate"
                      ></Datepicker>
                      <div
                        class="form-error"
                        v-if="formError && formError.tanggal"
                      >
                        {{ formError.tanggal }}
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group">
                        <label class="form-label">Departemen </label>
                        <Select2
                          v-model="oldformData.id_divisi"
                          :disabled="!isEditable"
                          :options="OptionDepartemen"
                          style="width: 100% !important"
                          placeholder="Pilih Departemen"
                          :settings="{
                            allowClear: !this.accessDepartement ? true : false,
                          }"
                          @change="formChange('id_divisi')"
                          @select="formChange('id_divisi')"
                        />
                      </div>
                      <label>Kas/Bank :</label>
                      <Select2
                        v-model="oldformData.id_akun"
                        :disabled="!isEditable"
                        :class="{
                          'is-invalid': formError && formError.id_akun,
                        }"
                        :options="OptionKasBank"
                        placeholder="Pilih Kas/Bank"
                        :settings="{ templateResult: formatState }"
                        @change="formChange($event)"
                        @select="formChange($event)"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_akun"
                      >
                        {{ formError.id_akun }}
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label for="no_referensi">Nomor Referensi :</label>
                      <input
                        type="text"
                        :disabled="!isEditable"
                        class="form-control"
                        v-model="oldformData.no_referensi"
                        id="no_referensi"
                        placeholder="Masukkan Nomor Referensi"
                      />
                    </div>

                    <div class="form-group">
                      <label>Status :</label>
                      <Select2
                        v-model="oldformData.status"
                        :disabled="!isEditable"
                        :class="{ 'is-invalid': formError && formError.status }"
                        :options="statusCompany"
                        placeholder="Status"
                        @change="formChange('status')"
                        @select="formChange('status')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.status"
                      >
                        {{ formError.status }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="keterangan">Catatan :</label>
                      <textarea
                        type="text"
                        :disabled="!isEditable"
                        v-model="oldformData.keterangan"
                        class="form-control"
                        id="keterangan"
                        placeholder="Masukkan Keterangan"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="title">Rincian Pengeluaran</div>
                    <div class="sub-title">
                      Rincian yang ada pada Pengeluaran
                    </div>
                  </div>
                </div>
                <hr />
                <div class="d-flex justify-content-end">
                  <div class="card-total">
                    <div class="total-debit d-flex flex-column">
                      <span>Nilai</span>
                      <span class="ml-auto"
                        >Rp {{ formatMoney(oldformData.nilai) }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="table-responsive mt-4">
                  <table
                    class="table table-bordered table-striped"
                    id="tableDepartment"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Akun</th>
                        <th style="width: 20%">Nama Akun</th>
                        <th style="width: 20%">Nilai</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in oldFormDetail" :key="index">
                        <td>{{ value.akun }}</td>
                        <td>{{ value.nama }}</td>
                        <td
                          class="text-right position-relative"
                          style="padding-right: 40px"
                        >
                          {{
                            value.tipe == "credit"
                              ? "-" + formatMoney(value.nilai)
                              : formatMoney(value.nilai)
                          }}
                        </td>
                      </tr>
                      <tr v-if="formDetail.length === 0">
                        <td colspan="3" class="text-center">
                          No data available in table
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
import Select2 from "vue3-select2-component";
import SidebarDashboard from "../../../../src/components/Sidebar.vue";
import NavbarDashboard from "../../../../src/components/Navbar.vue";
import Datepicker from "vue3-date-time-picker";
import $ from "jquery";
import { ref } from "vue";
import { loadScript } from "vue-plugin-load-script";
loadScript("/assets/js/stisla.js");
loadScript("/assets/js/scripts.js");
loadScript("/assets/js/custom.js");
loadScript("/assets/js/page/index.js");
import { get_AkunList } from "../../../actions/akun_perkiraan";
import {
  checkRules,
  cksClient,
  showAlert,
  checkModuleAccess,
} from "../../../helper";
import { VMoney } from "v-money";
import moment from "moment";
import { get_ListDivisi } from "../../../actions/master";
import {
  get_PengeluaranDetail,
  post_PengeluaranSave,
} from "../../../actions/pengeluaran";

export default {
  name: "DashboardLayout",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
  },

  directives: {
    money: VMoney,
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      accessDepartement: cksClient().get("_account").akses_departement,
      myValue: "aaaa",
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      optionPeriode: [
        "Januari - Desember",
        "Februari - Januari",
        "Maret - Februari",
        "April - Maret",
      ],
      OptionsDistrict: ["Solo", "Klaten"],
      OptionKasBank: [],
      OptionBranch: ["Jawa Tengah", "Yogyakarta"],
      OptionDepartemen: [],
      OptionAccountEstimated: [],
      no_transaksi: this.$route.params.id,
      label: this.$route.params.id ? "Edit" : "Tambah",
      id_company: cksClient().get("_account").id_company,
      formModal: {
        key: "",
        id: "",
        nama: "",
        nilai: "",
      },
      formData: {
        no_transaksi: this.$route.params.id ? this.$route.params.id : "",
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        id_akun: "",
        nilai: 0,
        tanggal: moment().format("YYYY-MM-DD"),
        no_referensi: "",
        keterangan: "",
        status: "aktif",
        no_jurnal: "",
        is_edit: false,
      },
      formError: [],
      rules: {
        id_akun: {
          required: true,
        },
        nilai: {
          required: true,
          min: 1,
        },
        tanggal: {
          required: true,
        },
        status: {
          required: true,
        },
      },
      AccountEstimated: "",
      onModal: false,
      formDetail: [],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      oldformData: {
        no_transaksi: "",
        id_company: "",
        id_divisi: "",
        id_akun: "",
        nilai: 0,
        tanggal: "",
        no_referensi: "",
        keterangan: "",
        status: "",
        no_jurnal: "",
      },
      oldFormDetail: [],
      isSubmit: false,
      isEditable: true,
      kodeProject: "",
      id_payroll: "",
      id_reimburse: "",
      kode_aset: "",
      msgAlert: "",

      //   dataRole: [
      //     {
      //       title: "Company",
      //       detail: [
      //         {
      //           akses: "Melihat dan update data company?",
      //           id: "",
      //           web: false,
      //           mobile: false,
      //         },
      //         {
      //           akses: "Melihat dan update data company?",
      //           id: "",
      //           web: false,
      //           mobile: false,
      //         },
      //         {
      //           akses: "Melihat dan update data company?",
      //           web: false,
      //           mobile: false,
      //         },
      //       ],
      //     },
      //     {
      //       title: "Client",
      //       detail: [
      //         {
      //           akses: "Melihat dan update data company?",
      //           id: "",
      //           web: false,
      //           mobile: false,
      //         },
      //         {
      //           akses: "Melihat dan update data company?",
      //           id: "",
      //           web: false,
      //           mobile: false,
      //         },
      //         {
      //           akses: "Melihat dan update data company?",
      //           id: "",
      //           web: false,
      //           mobile: false,
      //         },
      //       ],
      //     },
      //   ],
    };
  },

  created() {
    if (this.label == "Tambah" && this.accessDepartement) {
      var data = this.accessDepartement.split("|");
      this.formData.id_divisi = data[0];
    }
    if (this.no_transaksi) {
      this.getData();
    }
    this.getKasBank();
    this.getKasBank("1,4,5,7");
    this.getDevisi();

    if (!this.checkAccess("kas_bank", "cru_credit")) {
      this.isEditable = false;
    }
    // this.FormatState()
    //  this.myChangeEvent()
    //  this.mySelectEvent()
  },

  mounted() {
    setTimeout(() => {
      $("#pills-new-data-tab").tab("show");
      $("#pills-new-data").addClass("active show");
    }, 600);
  },

  methods: {
    getData() {
      get_PengeluaranDetail(
        this.no_transaksi,
        (res) => {
          const { detail, data, oldData } = res;
          this.formData = { ...this.formData, ...data };
          this.changeDate(data.tanggal);
          this.kodeProject = data.pengeluaran_project;
          this.id_reimburse = data.id_reimburse;
          this.id_payroll = data.id_payroll;
          this.kode_aset = data.kode_aset;
          if (this.id_reimburse) {
            this.msgAlert = `reimburse`;
            this.isEditable = false;
          } else if (this.kodeProject) {
            this.msgAlert = `project`;
            this.isEditable = false;
          } else if (this.id_payroll) {
            this.msgAlert = `payroll`;
            this.isEditable = false;
          } else if (this.kode_aset) {
            this.msgAlert = `aset`;
            this.isEditable = false;
          } else if (data.id_hutang) {
            this.msgAlert = `hutang`;
            this.isEditable = false;
          } else {
            this.msgAlert = "";
            this.isEditable = this.formData.status == "aktif" ? true : false;
          }
          // this.isEditable = data.pengeluaran_project ? false : true;
          this.date = data.tanggal;
          delete this.formData.pengeluaran_project;
          delete this.formData.created_at;
          delete this.formData.updated_at;
          for (const key in detail) {
            if (Object.hasOwnProperty.call(detail, key)) {
              var element = detail[key];
              this.formData.no_jurnal = element.no_jurnal;
              delete element.no_jurnal;
              this.formDetail.push(element);
            }
          }

          if (this.formData.status == "aktif") {
            this.oldformData = { ...this.oldformData, ...this.formData };
            this.oldFormDetail = [...this.formDetail];
          } else if (this.formData.status == "waiting") {
            this.statusCompany.push({
              id: "waiting",
              text: "Menunggu Persetujuan",
            });
            this.oldformData = oldData.data;
            this.oldFormDetail = oldData.detail;
          }
        },
        () => {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Data Pengeluaran tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataSpending",
              });
            },
          });
        }
      );
    },
    getDevisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.OptionDepartemen = list;
      });
    },
    getKasBank(tipe = "") {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }

          if (tipe == "1,4,5,7") {
            this.OptionKasBank = data;
          } else {
            this.OptionAccountEstimated = data;
          }
        }
      );
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },
    async formChange(val) {
      if (val === "id_divisi" && this.accessDepartement) {
        this.formDetail = [];
        this.formData.id_akun = "";
        this.countDetail();
        this.getKasBank();
        this.getKasBank("1,4,5,7");
      }
      if (Object.hasOwnProperty.call(this.rules, val)) {
        this.rules[val].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      this.checkHasBeenEdit();
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    changeDate(data) {
      this.formData.tanggal = moment(data).format("YYYY-MM-DD");
    },
    saveDetail() {
      if (this.formModal.nilai) {
        if (this.formModal.key !== "" && this.formModal.key >= 0) {
          this.formDetail[this.formModal.key] = {
            akun: this.formModal.id,
            nama: this.formModal.nama,
            nilai: this.formModal.nilai.replace(/\./g, ""),
          };
        } else {
          this.formDetail.push({
            akun: this.formModal.id,
            nama: this.formModal.nama,
            nilai: this.formModal.nilai.replace(/\./g, ""),
          });
        }
        this.countDetail();
        $("#modalAccountEstimated").modal("hide");
      }
    },
    editDetail(key, id, nama, nilai, ev) {
      if (ev.target && ev.target.className === "btn-add-row") {
        this.deleteDetail(key);
      } else {
        this.formModal.key = key;
        this.formModal.id = id;
        this.formModal.nilai = nilai;
        this.formModal.nama = nama;
        $("#modalAccountEstimated").modal("show");
      }
    },
    deleteDetail(key) {
      delete this.formDetail[key];
      this.formDetail.length -= 1;
      this.countDetail();
    },
    countDetail() {
      var nilai = 0;
      for (const key in this.formDetail) {
        if (Object.hasOwnProperty.call(this.formDetail, key)) {
          const element = this.formDetail[key];
          nilai += parseInt(element.nilai);
        }
      }
      this.formData.nilai = nilai;
      this.formChange();
    },
    mySelectAccountEstimated(ev) {
      if (ev.id) {
        this.onModal = true;
        this.formModal.key = "";
        this.formModal.id = ev.id;
        this.formModal.nilai = "";
        this.formModal.nama = ev.text;
        $("#modalAccountEstimated").modal("show");
        setTimeout(() => {
          this.AccountEstimated = "";
        }, 500);
      }
    },
    checkHasBeenEdit() {
      var isEdit = false;
      var formData = { ...this.formData };
      var oldformData = { ...this.oldformData };
      var formDetail = { ...this.formDetail };
      var oldFormDetail = { ...this.formDetail };

      delete formData.is_edit;
      delete oldformData.is_edit;

      if (
        (JSON.stringify(formData) != JSON.stringify(oldformData) ||
          JSON.stringify(formDetail) != JSON.stringify(oldFormDetail)) &&
        this.label == "Edit"
      ) {
        isEdit = true;
      }

      this.formData.is_edit = isEdit;
    },
    postData(status = "") {
      this.isSubmit = true;
      var data = { ...this.formData };
      data.jurnal_detail = this.formDetail;
      if (status != "") {
        data.status = status;
      }
      post_PengeluaranSave(
        data,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data pengeluaran berhasil dicatat"
              : "Data pengeluaran berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DataSpending",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success && (this.label == "Tambah" || (this.label == "Edit" && this.formData.is_edit))) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.no_transaksi
            ? this.formData.is_edit
              ? "Anda akan mengubah data, anda membutuhkan persetujuan agar data tersimpan dengan benar.<br> Apakah anda akan melanjutkannya?"
              : "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    accChange() {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: "Apakah anda yakin, Tindakan ini akan menyetujui semua data dan akan tersimpan real?",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Lanjutkan",
        onSubmit: () => {
          this.postData("disetujui");
        },
      });
    },

    rejectChange() {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: "Apakah anda yakin, Tindakan ini akan menolak semua data dan data akan dikembalikan lagi sebelum terjadinya perubahan?",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Lanjutkan",
        onSubmit: () => {
          this.postData("ditolak");
        },
      });
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}
</style>
