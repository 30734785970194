import { API } from "../../constans/Url"
import { apiClient } from "../../helper"


const get_PengeluaranList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PENGELUARAN.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PengeluaranDetail = (no_transaksi, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PENGELUARAN.GET, {no_transaksi: no_transaksi}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_PengeluaranSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PENGELUARAN.SAVE, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_PengeluaranList,
  get_PengeluaranDetail,
  post_PengeluaranSave
}